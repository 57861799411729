import { Fragment } from 'react'
import Product from '../Product/Product.jsx'

const Products = ({ products, query }) => {
    // filter out class products unless query is `class`
    if (query.indexOf('class') === -1) {
        products = products.filter((p) => {
            return p.product_type !== 'Virtual Classes'
        })
    }
    products = products.filter((p) => {
        return (
            p.title.indexOf('hidden') === -1 &&
            p.tags.indexOf('freeproduct') === -1
        )
    })

    products = products.slice(0, 8)

    return (
        <Fragment>
            {products && products.length > 0 && (
                <ul className="products-list nostyle grid grid-cols-2 sm:grid-cols-4 gap-6 search-products">
                    {products.map((product) => {
                        return (
                            <Product
                                key={product.id}
                                product={product}
                                query={query}
                            />
                        )
                    })}
                </ul>
            )}
        </Fragment>
    )
}

export default Products
